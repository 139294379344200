/* You can add global styles to this file, and also import other style files */
*,
body,
html {
  font-family: Quicksand, sans-serif !important;
  font-size: 12px;
}
.dx-tag-content {
  margin: 4px 0 0 4px;
  padding: 3px 25px 4px 6px;
  min-width: 40px;
  background-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-radius: 42px;
  color: #727e8c;
  border-color: #727e8c;
}
.dx-dropdowneditor-icon {
  border: 1px solid transparent;
  color: #333;
  font: 18px/18px DXIcons !important;
  width: 32px;
  height: 100%;
  text-align: center;
  border-radius: 3px;
}
.dx-datagrid-adaptive-more:before {
  font-family: "DXIcons";
}
.dx-searchbox .dx-icon-search {
  font: 17px/18px DXIcons !important;
  position: absolute !important;
  top: 50% !important;
  margin-top: -17px !important;
  width: 34px !important;
  height: 34px !important;
  background-position: 8px 8px !important;
  background-size: 18px 18px !important;
  padding: 8px !important;
  text-align: center !important;
  color: #939da1 !important;
}
.dx-datebox-calendar .dx-dropdowneditor-icon {
  font: 15px/19px DXIcons !important;
  width: 32px;
  /* height: 100%; */
  text-align: center;
  border-radius: 3px;
  color: #4c52bc;
  /* font-size: 15px!important; */
}
.dx-datagrid-group-opened {
  font: 18px/18px DXIcons !important;
  height: 18px;
  background-position: 0 0;
  background-size: 18px 18px;
  padding: 0;
  text-align: center;
  color: #959595;
}
.dx-datagrid-group-closed {
  font: 18px/18px DXIcons !important;
  height: 18px;
  background-position: 0 0;
  background-size: 18px 18px;
  padding: 0;
  text-align: center;
  color: #959595;
}
.dx-editor-with-menu .dx-filter-menu .dx-menu-item-content .dx-icon.dx-icon-filter-operation-default,
.dx-filter-menu .dx-menu-item-content .dx-icon.dx-icon-filter-operation-default {
  margin-top: 2px;
  font: 17px/18px DXIcons !important;
}
.dx-menu-item .dx-menu-item-content .dx-icon {
  margin: 0 3px;
  font: 17px/18px DXIcons !important;
}
.dx-checkbox-checked .dx-checkbox-icon {
  font: 16px/16px DXIcons !important;
  color: #4c52bc;
  text-align: center;
}
.dx-checkbox-indeterminate .dx-checkbox-icon {
  font: 14px/14px DXIcons !important;
  color: #4c52bc;
  text-align: center;
}
.dx-popup-title .dx-closebutton .dx-icon {
  box-sizing: border-box !important;
  width: 21px !important;
  height: 21px !important;
  background-position: 3px 3px !important;
  background-size: 15px 15px !important;
  padding: 3px !important;
  font-size: 15px !important;
  text-align: center !important;
  line-height: 15px !important;
}
.dx-field-item-help-text,
.dx-field-item-label-text {
  color: #4c52bc;
}
.dx-button-has-icon .dx-icon {
  width: 18px;
  height: 18px;
  background-position: 0 0;
  background-size: 18px 18px;
  padding: 0;
  font-size: 18px;
  text-align: center;
  line-height: 18px;
  margin-right: 0;
  margin-left: 0;
  font: 17px/18px DXIcons !important;
}
.dx-show-clear-button .dx-icon-clear {
  color: #4c52bc;
  position: absolute;
  top: 50%;
  margin-top: -17px;
  padding: 8px;
  width: 34px;
  height: 34px;
  background-position: 8px 8px;
  background-size: 18px 18px;
  font-size: 18px;
  text-align: center;
  line-height: 18px;
  font: 14px/1 DXIcons !important;
}
.dx-texteditor.dx-editor-outlined {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  border-radius: 30px;
}
.dx-pivotgrid-fields-container .dx-sort-up {
  font: 14px/1 DXIcons !important;
}
.dx-pivotgrid .dx-pivotgrid-collapsed .dx-expand {
  font: 18px/18px DXIcons !important;
  text-align: center;
  color: #959595;
}
.dx-pivotgrid-fields-container .dx-header-filter {
  color: #959595;
  font: 14px/1 DXIcons !important;
  width: 14px;
}
.material-icons {
  font-family: "Material Icons" !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}